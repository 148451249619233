import { default as indexuxomhkjlS8Meta } from "/app/pages/ams/administration/index.vue?macro=true";
import { default as indexPl9IeOxfx0Meta } from "/app/pages/ams/employees/index.vue?macro=true";
import { default as indexP50FKH2LjsMeta } from "/app/pages/ams/fleet/index.vue?macro=true";
import { default as testy96fnFENfdMeta } from "/app/pages/ams/fleet/test.vue?macro=true";
import { default as indexziFk2rjNghMeta } from "/app/pages/ams/hangar/index.vue?macro=true";
import { default as indexR5qiXmilQdMeta } from "/app/pages/ams/home/index.vue?macro=true";
import { default as indexhZrYnc08dIMeta } from "/app/pages/ams/login/index.vue?macro=true";
import { default as indexduuNT5EC5eMeta } from "/app/pages/ams/login/pw-reset/index.vue?macro=true";
import { default as index3DA0X1yZV2Meta } from "/app/pages/ams/messages/index.vue?macro=true";
import { default as helpjisRXzaO4iMeta } from "/app/pages/ams/profile/help.vue?macro=true";
import { default as index4cMRpjxLrBMeta } from "/app/pages/ams/profile/index.vue?macro=true";
import { default as notifications2cUlnKqB5hMeta } from "/app/pages/ams/profile/notifications.vue?macro=true";
import { default as privacy2iEPPgNV3kMeta } from "/app/pages/ams/profile/privacy.vue?macro=true";
import { default as profileBViKXIxp8hMeta } from "/app/pages/ams/profile.vue?macro=true";
import { default as calculatoroQZpeDyPnBMeta } from "/app/pages/ams/toolbox/calculator.vue?macro=true";
import { default as index2EGlTynVuxMeta } from "/app/pages/ams/toolbox/index.vue?macro=true";
import { default as _91slug_93mFQT12uQ8rMeta } from "/app/pages/biography/[slug].vue?macro=true";
import { default as indexfq18sMYVeVMeta } from "/app/pages/comm-link/index.vue?macro=true";
import { default as _91id_93vTca0x9KzeMeta } from "/app/pages/comm-link/transmission/[id].vue?macro=true";
import { default as indexcJREbfdBpaMeta } from "/app/pages/credits/index.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as sentrytestDNRoRTU7zkMeta } from "/app/pages/sentrytest.vue?macro=true";
import { default as indexMCX7SqxwwMMeta } from "/app/pages/ShipExkurs/home/index.vue?macro=true";
import { default as indexCsEuX3zc2AMeta } from "/app/pages/ShipExkurs/ships/[slug]/index.vue?macro=true";
import { default as indexvBpTpYrOBjMeta } from "/app/pages/ShipExkurs/ships/index.vue?macro=true";
import { default as _91slug_93MxewltjdC7Meta } from "/app/pages/ShipExkurs/ships/test/[slug].vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
import { default as test2nqHLJNjxdWMeta } from "/app/pages/test2.vue?macro=true";
import { default as _91slug_93IaC5Tf8mnOMeta } from "/app/pages/verseexkurs/aliens/[slug].vue?macro=true";
import { default as faunajfI3sSQWk2Meta } from "/app/pages/verseexkurs/aliens/fauna.vue?macro=true";
import { default as flora311AI9HJ3wMeta } from "/app/pages/verseexkurs/aliens/flora.vue?macro=true";
import { default as index0W6G9fgXBoMeta } from "/app/pages/verseexkurs/aliens/index.vue?macro=true";
import { default as indexe3SOCnIejFMeta } from "/app/pages/verseexkurs/companies/[slug]/index.vue?macro=true";
import { default as index9SKNPyOcHXMeta } from "/app/pages/verseexkurs/companies/index.vue?macro=true";
import { default as _91slug_93u6EXaRGZakMeta } from "/app/pages/verseexkurs/fractions/[slug].vue?macro=true";
import { default as indexJGqqS4rZK1Meta } from "/app/pages/verseexkurs/fractions/index.vue?macro=true";
import { default as indext3NPmtxHysMeta } from "/app/pages/verseexkurs/home/index.vue?macro=true";
import { default as indexrF4IKF9PbuMeta } from "/app/pages/verseexkurs/literature/[slug]/[chapter]/index.vue?macro=true";
import { default as indexAthKCgRsAeMeta } from "/app/pages/verseexkurs/literature/[slug]/index.vue?macro=true";
import { default as indexLWM9gU7jobMeta } from "/app/pages/verseexkurs/literature/index.vue?macro=true";
import { default as indexIT7AbDLNvYMeta } from "/app/pages/verseexkurs/one-day-in-history/[slug]/index.vue?macro=true";
import { default as indexvTHh5AXM1GMeta } from "/app/pages/verseexkurs/one-day-in-history/index.vue?macro=true";
import { default as indexI3KVedJkHXMeta } from "/app/pages/verseexkurs/spectrum/[slug]/[slug]/index.vue?macro=true";
import { default as index72WteIp1mLMeta } from "/app/pages/verseexkurs/spectrum/[slug]/index.vue?macro=true";
import { default as index6ojTUhU6BpMeta } from "/app/pages/verseexkurs/spectrum/index.vue?macro=true";
import { default as _91slug_93cd0LBJhHIkMeta } from "/app/pages/verseexkurs/starmap/[slug].vue?macro=true";
import { default as indexqvxirb1rdAMeta } from "/app/pages/verseexkurs/starmap/index.vue?macro=true";
import { default as indexJRezwnZ4ALMeta } from "/app/pages/verseexkurs/technology/[slug]/index.vue?macro=true";
import { default as index7UlWA48LCzMeta } from "/app/pages/verseexkurs/technology/attachments/[slug]/index.vue?macro=true";
import { default as indexFoPcNX7fVuMeta } from "/app/pages/verseexkurs/technology/attachments/index.vue?macro=true";
import { default as indexblmquqzBqZMeta } from "/app/pages/verseexkurs/technology/index.vue?macro=true";
import { default as indexoKdUNzLt5ZMeta } from "/app/pages/verseexkurs/technology/weapons/[slug]/index.vue?macro=true";
import { default as index0dO544dkQyMeta } from "/app/pages/verseexkurs/technology/weapons/index.vue?macro=true";
import { default as indexeJnUic5ccQMeta } from "/app/pages/verseexkurs/timeline/index.vue?macro=true";
import { default as indexqN6ZbCC7kTMeta } from "/app/pages/verseexkurs/uee/index.vue?macro=true";
export default [
  {
    name: "ams-administration",
    path: "/ams/administration",
    meta: indexuxomhkjlS8Meta || {},
    component: () => import("/app/pages/ams/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-employees",
    path: "/ams/employees",
    meta: indexPl9IeOxfx0Meta || {},
    component: () => import("/app/pages/ams/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-fleet",
    path: "/ams/fleet",
    meta: indexP50FKH2LjsMeta || {},
    component: () => import("/app/pages/ams/fleet/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-fleet-test",
    path: "/ams/fleet/test",
    meta: testy96fnFENfdMeta || {},
    alias: ["/ams/employeess/hangar/:slug()"],
    component: () => import("/app/pages/ams/fleet/test.vue").then(m => m.default || m)
  },
  {
    name: "ams-hangar",
    path: "/ams/hangar",
    meta: indexziFk2rjNghMeta || {},
    alias: ["/ams/employees/hangar/:slug"],
    component: () => import("/app/pages/ams/hangar/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-home",
    path: "/ams",
    meta: indexR5qiXmilQdMeta || {},
    component: () => import("/app/pages/ams/home/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-login",
    path: "/ams/login",
    meta: indexhZrYnc08dIMeta || {},
    component: () => import("/app/pages/ams/login/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-login-pw-reset",
    path: "/ams/login/pw-reset",
    meta: indexduuNT5EC5eMeta || {},
    component: () => import("/app/pages/ams/login/pw-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-messages",
    path: "/ams/messages",
    meta: index3DA0X1yZV2Meta || {},
    component: () => import("/app/pages/ams/messages/index.vue").then(m => m.default || m)
  },
  {
    name: profileBViKXIxp8hMeta?.name,
    path: "/ams/profile",
    meta: profileBViKXIxp8hMeta || {},
    component: () => import("/app/pages/ams/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "ams-profile-help",
    path: "help",
    component: () => import("/app/pages/ams/profile/help.vue").then(m => m.default || m)
  },
  {
    name: "ams-profile",
    path: "",
    meta: index4cMRpjxLrBMeta || {},
    component: () => import("/app/pages/ams/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "ams-profile-notifications",
    path: "notifications",
    meta: notifications2cUlnKqB5hMeta || {},
    component: () => import("/app/pages/ams/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "ams-profile-privacy",
    path: "privacy",
    component: () => import("/app/pages/ams/profile/privacy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ams-toolbox-calculator",
    path: "/ams/toolbox/calculator",
    meta: calculatoroQZpeDyPnBMeta || {},
    component: () => import("/app/pages/ams/toolbox/calculator.vue").then(m => m.default || m)
  },
  {
    name: "ams-toolbox",
    path: "/ams/toolbox",
    meta: index2EGlTynVuxMeta || {},
    component: () => import("/app/pages/ams/toolbox/index.vue").then(m => m.default || m)
  },
  {
    name: "biography-slug",
    path: "/biography/:slug()",
    meta: _91slug_93mFQT12uQ8rMeta || {},
    alias: ["/ams/employees/biography/:slug()"],
    component: () => import("/app/pages/biography/[slug].vue").then(m => m.default || m)
  },
  {
    name: "comm-link",
    path: "/comm-link",
    meta: indexfq18sMYVeVMeta || {},
    component: () => import("/app/pages/comm-link/index.vue").then(m => m.default || m)
  },
  {
    name: "comm-link-transmission-id",
    path: "/comm-link/transmission/:id()",
    meta: _91id_93vTca0x9KzeMeta || {},
    component: () => import("/app/pages/comm-link/transmission/[id].vue").then(m => m.default || m)
  },
  {
    name: "credits",
    path: "/credits",
    meta: indexcJREbfdBpaMeta || {},
    component: () => import("/app/pages/credits/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    component: () => import("/app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "sentrytest",
    path: "/sentrytest",
    component: () => import("/app/pages/sentrytest.vue").then(m => m.default || m)
  },
  {
    name: "ShipExkurs-home",
    path: "/shipexkurs",
    meta: indexMCX7SqxwwMMeta || {},
    component: () => import("/app/pages/ShipExkurs/home/index.vue").then(m => m.default || m)
  },
  {
    name: "ShipExkurs-ships-slug",
    path: "/ShipExkurs/ships/:slug()",
    meta: indexCsEuX3zc2AMeta || {},
    component: () => import("/app/pages/ShipExkurs/ships/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "ShipExkurs-ships",
    path: "/ShipExkurs/ships",
    meta: indexvBpTpYrOBjMeta || {},
    component: () => import("/app/pages/ShipExkurs/ships/index.vue").then(m => m.default || m)
  },
  {
    name: "ShipExkurs-ships-test-slug",
    path: "/ShipExkurs/ships/test/:slug()",
    meta: _91slug_93MxewltjdC7Meta || {},
    component: () => import("/app/pages/ShipExkurs/ships/test/[slug].vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test2",
    path: "/test2",
    component: () => import("/app/pages/test2.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-aliens-slug",
    path: "/verseexkurs/aliens/:slug()",
    meta: _91slug_93IaC5Tf8mnOMeta || {},
    component: () => import("/app/pages/verseexkurs/aliens/[slug].vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-aliens-fauna",
    path: "/verseexkurs/aliens/fauna",
    meta: faunajfI3sSQWk2Meta || {},
    component: () => import("/app/pages/verseexkurs/aliens/fauna.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-aliens-flora",
    path: "/verseexkurs/aliens/flora",
    meta: flora311AI9HJ3wMeta || {},
    component: () => import("/app/pages/verseexkurs/aliens/flora.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-aliens",
    path: "/verseexkurs/aliens",
    meta: index0W6G9fgXBoMeta || {},
    component: () => import("/app/pages/verseexkurs/aliens/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-companies-slug",
    path: "/verseexkurs/companies/:slug()",
    meta: indexe3SOCnIejFMeta || {},
    component: () => import("/app/pages/verseexkurs/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-companies",
    path: "/verseexkurs/companies",
    meta: index9SKNPyOcHXMeta || {},
    component: () => import("/app/pages/verseexkurs/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-fractions-slug",
    path: "/verseexkurs/fractions/:slug()",
    meta: _91slug_93u6EXaRGZakMeta || {},
    component: () => import("/app/pages/verseexkurs/fractions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-fractions",
    path: "/verseexkurs/fractions",
    meta: indexJGqqS4rZK1Meta || {},
    component: () => import("/app/pages/verseexkurs/fractions/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-home",
    path: "/verseexkurs",
    meta: indext3NPmtxHysMeta || {},
    component: () => import("/app/pages/verseexkurs/home/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-literature-slug-chapter",
    path: "/verseexkurs/literature/:slug()/:chapter()",
    meta: indexrF4IKF9PbuMeta || {},
    component: () => import("/app/pages/verseexkurs/literature/[slug]/[chapter]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-literature-slug",
    path: "/verseexkurs/literature/:slug()",
    meta: indexAthKCgRsAeMeta || {},
    component: () => import("/app/pages/verseexkurs/literature/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-literature",
    path: "/verseexkurs/literature",
    meta: indexLWM9gU7jobMeta || {},
    component: () => import("/app/pages/verseexkurs/literature/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-one-day-in-history-slug",
    path: "/verseexkurs/one-day-in-history/:slug()",
    meta: indexIT7AbDLNvYMeta || {},
    component: () => import("/app/pages/verseexkurs/one-day-in-history/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-one-day-in-history",
    path: "/verseexkurs/one-day-in-history",
    meta: indexvTHh5AXM1GMeta || {},
    component: () => import("/app/pages/verseexkurs/one-day-in-history/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-spectrum-slug-slug",
    path: "/verseexkurs/spectrum/:slug()/:slug()",
    meta: indexI3KVedJkHXMeta || {},
    component: () => import("/app/pages/verseexkurs/spectrum/[slug]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-spectrum-slug",
    path: "/verseexkurs/spectrum/:slug()",
    meta: index72WteIp1mLMeta || {},
    component: () => import("/app/pages/verseexkurs/spectrum/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-spectrum",
    path: "/verseexkurs/spectrum",
    meta: index6ojTUhU6BpMeta || {},
    component: () => import("/app/pages/verseexkurs/spectrum/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-starmap-slug",
    path: "/verseexkurs/starmap/:slug()",
    meta: _91slug_93cd0LBJhHIkMeta || {},
    component: () => import("/app/pages/verseexkurs/starmap/[slug].vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-starmap",
    path: "/verseexkurs/starmap",
    meta: indexqvxirb1rdAMeta || {},
    component: () => import("/app/pages/verseexkurs/starmap/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-technology-slug",
    path: "/verseexkurs/technology/:slug()",
    meta: indexJRezwnZ4ALMeta || {},
    component: () => import("/app/pages/verseexkurs/technology/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-technology-attachments-slug",
    path: "/verseexkurs/technology/attachments/:slug()",
    meta: index7UlWA48LCzMeta || {},
    component: () => import("/app/pages/verseexkurs/technology/attachments/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-technology-attachments",
    path: "/verseexkurs/technology/attachments",
    meta: indexFoPcNX7fVuMeta || {},
    component: () => import("/app/pages/verseexkurs/technology/attachments/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-technology",
    path: "/verseexkurs/technology",
    meta: indexblmquqzBqZMeta || {},
    component: () => import("/app/pages/verseexkurs/technology/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-technology-weapons-slug",
    path: "/verseexkurs/technology/weapons/:slug()",
    meta: indexoKdUNzLt5ZMeta || {},
    component: () => import("/app/pages/verseexkurs/technology/weapons/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-technology-weapons",
    path: "/verseexkurs/technology/weapons",
    meta: index0dO544dkQyMeta || {},
    component: () => import("/app/pages/verseexkurs/technology/weapons/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-timeline",
    path: "/verseexkurs/timeline",
    meta: indexeJnUic5ccQMeta || {},
    component: () => import("/app/pages/verseexkurs/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: "verseexkurs-uee",
    path: "/verseexkurs/uee",
    meta: indexqN6ZbCC7kTMeta || {},
    component: () => import("/app/pages/verseexkurs/uee/index.vue").then(m => m.default || m)
  }
]